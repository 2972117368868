import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-dashboard-pay-success',
    templateUrl: './dashboard-pay-success.component.html',
    styleUrls: ['./dashboard-pay-success.component.css'],
    standalone: false
})
export class DashboardPaySuccessComponent implements OnInit {
  sub_state: string | null = null;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    // Get the session_id from the URL parameters
    this.route.queryParams.subscribe(params => {
      this.sub_state = params['sub_state'];
    });
  }
}
