// github-callback.component.ts
import { Component, OnInit } from '@angular/core';
import { GithubService } from '../../github.service';

@Component({
    selector: 'app-github-callback',
    template: '<div class="loader"></div>',
    styles: [
        `
      .loader {
        border: 8px solid #f3f3f3;
        border-top: 8px solid #3498db;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite;
        margin: 20px auto;
      }

      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `,
    ],
    standalone: false
})
export class GithubCallbackComponent implements OnInit {
  constructor(private githubService: GithubService) {}

  ngOnInit() {
    // Handle the callback logic, e.g., exchange code for access token
    this.githubService.handleCallback();
  }
}