// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  homeUrl : 'https://metadev.app.setinstone.fr',
  labUrl: "https://lab.setinstone.io/",
  requestUrl : 'https://metadev.app.setinstone.fr/app',
  dashboardUrl : 'https://metadev.app.setinstone.fr/app/user-dashboard',
  githubUrl: 'https://github.com/login/oauth/authorize',
  originCode: "Basic SIS Y$Wxh-GR%pbjpvcGVuc2VzRGHXDKCORMSDPKDOQXIDJCOEZ$W1l",
  secret: "q2IpkvDnEPgc4L0liCHKwIzPt8ABtSrDdENrJ8AT7eQ=",
  clientId: "a4ee2c943fc71dbbf826",
  reCaptchaKey: "6Ldntq4oAAAAAEVuHh-Ujgx0m3erVD-hQhdpCS4d",
  maxSizeMb: 650,
  secretKeyCookie: 'r8T8LB85p>%;P"-IHz?`wW)ai<`W(p',
  secretKey: 'Gd.m<$A186$jZm1Gj]>8NnJm4GP.pv',
  email: 'hi@setinstone.io',
  privacy: 'https://lab.setinstone.io/legal',
  text: "📝 To fixe these vulnerabilities, you must to subscribe to a full audit : https://calendly.com/set-in-stone-thomas-benoit/prepare-a-security-audit",
  k1: 2,
  k2: 5,
  linkButton: "Get a free pre-audit report of your smartcontracts today",
  linkNotionRules:"https://booming-sheep-751.notion.site/Set-In-Stone-Pre-Audit-Rules-d1904324426044b29770e21f31057fed?pvs=74",
  generalRules:"https://lab.setinstone.io/cgps",
  contactLink:"https://calendly.com/set-in-stone-thomas-benoit/prepare-a-security-audit",
  sizeUpload: 100,
  xmessage: "Gm 👋 Great news ! My project has passed a @SETINSTONEweb3 pre-audit. The Fundamental Securiy Confirmation is online :",
  telegram_message: "Gm 👋 Great news ! The project has passed a SET IN STONE pre-audit. The Fundamental Securiy Confirmation is online 😎",
  clientIdApp:'Iv23li2TSD9dixERhKQn',
  appName:'METADEVAPP',
  appId:'981572',
  individualPriceMonthly: '19,99€',
  individualPriceAnnually: '14,99€',
  professionalPriceMonthly: '99,99€',
  professionalPriceAnnually: '79,99€',
  individualAuditDescription: '1 Audit / Month',
  professionalAuditDescription: '10 Audits / Month'
};
