import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-unsubscribe-dialog',
  templateUrl: './unsubscribe-dialog.component.html',
  styleUrls: ['./unsubscribe-dialog.component.css']
})
export class UnsubscribeDialogComponent {
  userInput: string = '';
  @Output() confirmed = new EventEmitter<boolean>();
  @Output() canceled = new EventEmitter<void>();

  confirm() {
    if (this.userInput === 'unsubscribe') {
      this.confirmed.emit(true);
    } else {
      alert('Please type "unsubscribe" to confirm.');
    }
  }

  cancel() {
    this.canceled.emit();
  }
}
