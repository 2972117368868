import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServerService } from '../../server.service';
import { environment } from 'src/app/environments/environment';

@Component({
  selector: 'app-scan-report',
  templateUrl: './scan-report.component.html',
  styleUrls: ['./scan-report.component.css']
})
export class ScanReportComponent implements OnInit {
  scanData: {
    scan: { 
      score: number, 
      VULS: { 
        flaws: { 
          High: number, 
          Medium: number, 
          Low: number 
        }, 
        warnings: number 
      } 
    },
    resultCreationTime: string,
    resultExpirationTime: string,
    expirationTime: string,
    contentScanned: string,
    bc_address: string,
    bc_name: string,
    message: string,
    message2: string,
    vulReportUrl: string
  } = {
    scan: { 
      score: 0, 
      VULS: { 
        flaws: { 
          High: 0, 
          Medium: 0, 
          Low: 0 
        }, 
        warnings: 0 
      } 
    },
    resultCreationTime: '',
    resultExpirationTime: '',
    expirationTime: '',
    contentScanned: '',
    bc_address: '',
    bc_name: '',
    message: '',
    message2: '',
    vulReportUrl: ''
  };
  errorMessage: string | null = 'loading';
  scanHash: string = '0x';
  scanScore: number = 0;
  animatedNumber: number = 0; // Number displayed to the user
  animationDuration: number = 2000; // Total animation duration in milliseconds
  shareOnTelegram: string = '';
  shareOnTwitter: string = '';
  isLoading: boolean = false;
  warningMessage: string | null = null; // Add a warning message property
  isLinkCopied: boolean = false; // New property to track link copy status
  menu_URL: string = environment.homeUrl;                 // Base URL of the application
  LAB_URL: string = environment.labUrl; 
  REQUEST_URL: string = environment.homeUrl;
  constructor(
    private route: ActivatedRoute,
    private serverService: ServerService,
  ) {}

  async ngOnInit(): Promise<void> {
    console.log("Component init ...");
    const urlParts = this.route.snapshot.url.map(segment => segment.path);
    this.scanHash = urlParts[urlParts.length - 1];
    await this.handleScanData();
  
  }

  async fetchScanData(): Promise<void> {
    await this.handleScanData();
  }

  copyToClipboard() {
    const urlToCopy = environment.homeUrl + '/scan/' + this.scanHash;
    navigator.clipboard.writeText(urlToCopy).then(() => {
      this.isLinkCopied = true; // Set the link copied state
      setTimeout(() => {
        this.isLinkCopied = false; // Reset the state after 3 seconds
      }, 1000);
    }).catch(err => {
      console.error('Failed to copy: ', err);
      this.warningMessage = 'Failed to copy URL.'; // Set error message
    });
  }

  async handleScanData() {
    try {
      this.isLoading = true;
      this.scanData = await this.serverService.fetchScanData(this.scanHash);

      
      console.log(this.scanData.contentScanned);
      if (this.scanData.resultCreationTime === ''){
        this.errorMessage = 'loading';
        return;
      } 
        

      this.animateNumber();
      setTimeout(() => {
        this.scanScore = Math.round(this.scanData.scan.score); // Update to actual score
      }, 100); 
      this.isLoading = false;
      
      // Set up sharing links
      const URLToEncode = environment.homeUrl + '/scan/' + this.scanHash;
      const message = encodeURIComponent('Check out my scan results!');
      this.shareOnTelegram = `https://t.me/share/url?url=${encodeURIComponent(URLToEncode)}&text=${message}`;
      this.shareOnTwitter = `https://twitter.com/intent/tweet?text=${message}&url=${encodeURIComponent(URLToEncode)}`;
      
      this.errorMessage = null;
      
    } catch (error: unknown) {
      console.log(error);
      if (error !== null) {
        this.errorMessage = 'error';
      } 
    } finally {
      this.isLoading = false;
      const refreshSvg = document.querySelector('svg.refresh-svg');
      if (refreshSvg) {
        refreshSvg.classList.remove('animate');
      }
    }
    
  }

  animateNumber(): void {
    const frameRate = 30; // Number of updates per second (FPS)
    const totalFrames = this.animationDuration / (1000 / frameRate);
    const increment = (this.scanData.scan.score) / totalFrames;

    let currentFrame = 0;

    const interval = setInterval(() => {
      currentFrame++;
      this.animatedNumber = Math.min(
        Math.round(currentFrame * increment),
        Math.round(this.scanData.scan.score)
      );
      
      if (currentFrame >= totalFrames) {
        clearInterval(interval);
      }
    }, 1000 / frameRate);
  }
}
