<div class="success-container">
    <h1>Thank You for Your Subscription!</h1>
    <ng-container *ngIf="sub_state === 'register'">
        <p>Your subscription is now active.</p>
    </ng-container>
    <ng-container *ngIf="sub_state === 'update'">
        <p>Your subscription has been successfully updated.</p>
    </ng-container>
    <p>You can now use the platform and enjoy all the features available to you.</p>

    <br /> <br />
    <a routerLink="/dashboard" class="back-button">Go to Dashboard</a>
</div>