import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ServerService } from 'src/app/server.service'
import { environment } from 'src/app/environments/environment'
import { ReportAuthComponent } from '../report-auth/report-auth.component'
import { MatDialog } from '@angular/material/dialog'
import { Title } from '@angular/platform-browser';
import * as Prism from 'prismjs'
import 'prismjs/components/prism-solidity'

interface Vulnerability {
  id: string
  check: string
  position: string
  vul_analysis: string
  vul_solution: string
  isExpanded: boolean
  impact: string
  confidence: string
}

interface VulnerabilityPattern {
  id: string
  vul_analysis: string
  vul_solution: string
  count: string
  value: string
  isExpanded: boolean
  check: string
  impact: string
  confidence: string
  position: string
  position2: []
}

interface Vulnerabilities {
  [key: string]: Vulnerability[]
}

interface VulnerabilityCategory {
  vulnerabilities: Vulnerability[]
  isExpanded?: boolean
}

interface VulnerabilityPatternCategory {
  vulnerabilities: VulnerabilityPattern[]
  isExpanded?: boolean
}

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.css', '../../../../node_modules/prismjs/themes/prism.css'],
    standalone: false
})
export class ReportComponent implements OnInit {
  isLoaded = false // Flag to check if the data is loaded
  URLHash: any // Holds the URL parameter hash
  isHoveringText: boolean = false // Flag for hover effect on text
  displayData: any // Data retrieved from the server
  coverImageUrl: any // URL for the cover image
  headerTitle: any // Title for the report header
  pageDescription: any // Description for the page
  introText: any // Introduction text for the report
  vulnerabilities!: Vulnerabilities // Stores all vulnerabilities
  vuln!: Vulnerability[] // Array of individual vulnerabilities
  vulnerabilitiesPatterns!: VulnerabilityPattern[] // Array of vulnerability patterns
  warnings!: Vulnerability[] // Array of warning-type vulnerabilities
  pageTitle: any // Title for the page
  overlayElement: HTMLElement | null = null // Overlay element for modal dialogs
  sortedVulnerabilities: VulnerabilityCategory[] = [] // Sorted vulnerabilities by category
  sortedWarnings: VulnerabilityCategory[] = [] // Sorted warnings by category
  sortedVulnerabilitiesPattern: VulnerabilityPatternCategory[] = [] // Sorted vulnerability patterns by category
  isMobile: boolean = false // Mobile device detection flag
  nbVul: any // Number of vulnerabilities
  cost: any // Cost data
  public URL: any // Home URL from environment variables
  public LAB_URL: any // Lab URL from environment variables
  k1: number = environment.k1 // Security parameter k1
  k2: number = environment.k2 // Security parameter k2
  displayedColumns: string[] = ['severity', 'count'] // Columns for vulnerability table
  linkHome: string = environment.homeUrl // Home link
  linkLab: string = environment.labUrl // Lab link
  count: any // Vulnerability count object
  visiblePositions: string[] = [] // Positions visible in the report
  showAll = false // Flag to toggle between showing all vulnerabilities or a limited number
  numPositionsToShowInitially = 3 // Initial number of positions to show
  username!: string // Username from the report
  repo!: string // Repository name from the report
  free: boolean = true // Free or paid version flag
  nbVuln!: number // Number of vulnerabilities
  nbWarning!: number // Number of warnings
  nbHigh!: number // Number of high severity vulnerabilities
  contactLink = environment.contactLink // Contact link from environment variables
  isUpload: boolean = false // Flag to check if the report is uploaded

  constructor(
    private route: ActivatedRoute,
    public server: ServerService,
    public dialog: MatDialog,
    private titleService: Title
  ) {
    this.isMobile = this.server.isMobile // Determine if the device is mobile
  }

  async ngOnInit() {
    this.titleService.setTitle(`Report`) // Set the page title
    // Retrieve the URL parameter from the route
    this.route.params.subscribe(async (params) => {
      const paramValue = params['param']
      this.URLHash = paramValue // Set the URL hash parameter
      this.URL = environment.homeUrl
      this.LAB_URL = environment.labUrl
      this.openVerificationDialog() // Open the verification dialog
    })
  }

  // Toggles the background color of the icon based on hover state
  toggleIconBackground(isHovering: boolean): void {
    this.isHoveringText = isHovering
  }

  // Toggles the expansion of vulnerability details
  toggleVulnerabilityDetail(vulnerability: Vulnerability): void {
    vulnerability.isExpanded = !vulnerability.isExpanded
  }

  // Toggles the expansion of vulnerability pattern details
  toggleVulnerabilityDetailPattern(vulnerability: VulnerabilityPattern): void {
    vulnerability.isExpanded = !vulnerability.isExpanded
  }

  // Toggle to show all or limited number of vulnerabilities
  toggleShowAll() {
    this.showAll = !this.showAll
  }

  // Create overlay for modal dialogs
  createOverlay() {
    this.overlayElement = document.createElement('div')
    this.overlayElement.classList.add('modal-overlay')
    document.body.appendChild(this.overlayElement)
  }

  // Remove overlay when modal is closed
  removeOverlay() {
    if (this.overlayElement) {
      document.body.removeChild(this.overlayElement)
      this.overlayElement = null
    }
  }

  // Scrolls the page to the top
  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  // Constructs a GitHub URL based on the position of the vulnerability in the code
  getGitHubURL(position: string): string {
    if (position.includes('forge-std')) {
      return ''
    }
    if (!position.includes('node_modules')) {
      return `https://github.com/${this.username}/${this.repo}/blob/master/${position.slice(0, -3)}`
    } else {
      return ''
    }
  }

  // Opens the verification dialog for user authentication
  openVerificationDialog(): void {
    this.createOverlay() // Add overlay
    const dialogRef = this.dialog.open(ReportAuthComponent, {
      disableClose: true,
      width: '550px',
      data: { hash: this.URLHash }, // Pass URL hash to the dialog
    })

    dialogRef.componentInstance.verificationData.subscribe(async (verified) => {
      if (verified) {
        // Load the main component's data
        this.displayData = verified // Set verified data

        // Extract relevant data from the verification
        this.username = this.displayData.username
        this.repo = this.displayData.repository
        this.count = this.displayData.VULNERABILITY_REPORT.REPORT_JSON.COUNT
        this.isUpload = this.displayData.isUpload
        this.introText = this.processTextContent(this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.INTRO)
        this.coverImageUrl = '../../assets/cover.jpg'
        let creationTime = this.displayData.creationTime
        let dateTimeParts = creationTime.split(' ')
        let timePart = dateTimeParts[4]
        let timeWithoutSeconds = timePart.split(':').slice(0, 2).join(':')
        this.headerTitle = 'Vulnerability Report - ' + dateTimeParts.slice(0, 4).join(' ') + ' ' + timeWithoutSeconds
        this.vuln = [].concat(
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.VULNERABILITIES.High,
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.VULNERABILITIES.Medium,
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.VULNERABILITIES.Low,
        )

        this.nbVuln = this.count.VULNERABILITIES - this.vuln.length
        this.nbHigh = this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.VULNERABILITIES.High.length
        this.vulnerabilitiesPatterns = [].concat(
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.VULPATTERNS.High,
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.VULPATTERNS.Medium,
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.VULPATTERNS.Low,
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.VULPATTERNS.Informational,
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.VULPATTERNS.Optimization,
        )

        this.warnings = [].concat(
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.WARNINGS.High,
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.WARNINGS.Medium,
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.WARNINGS.Low,
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.WARNINGS.Informational,
          this.displayData.VULNERABILITY_REPORT.REPORT_JSON.REPORT_JSON_V2.WARNINGS.Optimization,
        )

        this.pageTitle = 'Vulnerability Report'
        this.nbVul = this.displayData.VULNERABILITY_REPORT.REPORT_JSON.NB_VUL

        if (this.displayData.selectedPlan == 'lvl1') {
          this.free = false
        }

        this.cost = this.displayData.cost
        this.processVulnerabilitiesV2()
        this.processVulnerabilitiesPattern()
        this.processWarnings()
        this.removeOverlay()
        dialogRef.close()
        this.isLoaded = true
      }
    })
  }

  cleanJsonData(jsonData: any): any {
    if (typeof jsonData === 'string') {
      return jsonData.replace(/(```|'''|(\[''\]))/g, '');
    } else if (typeof jsonData === 'string') {
      return jsonData.replace(/Code snippet:/g, '')
    } else if (Array.isArray(jsonData)) {
      return jsonData.map((item) => this.cleanJsonData(item))
    } else if (typeof jsonData === 'object' && jsonData !== null) {
      const cleanedData: { [key: string]: any } = {} // Define the object with index signature
      Object.keys(jsonData).forEach((key) => {
        cleanedData[key] = this.cleanJsonData(jsonData[key])
      })
      return cleanedData
    } else {
      return jsonData
    }
  }

  processVulnerabilitiesV2() {
    let sortedVulnerabilities: VulnerabilityCategory[] = []
    let tmp: Vulnerability[] = []
    this.vuln.forEach((vulnerability, index) => {
      vulnerability.id = `${index + 1}`

      // Process other aspects of the vulnerability
      vulnerability.vul_analysis = this.highlightCodeBlocks(vulnerability.vul_analysis)
      vulnerability.vul_solution = this.highlightCodeBlocks(vulnerability.vul_solution)
      vulnerability.vul_solution = this.highlightCodeBlocksV2(vulnerability.vul_solution)
      // vulnerability.vul_solution = this.highlightCodeBlocksV3(vulnerability.vul_solution)

      // Wrap specific text with <strong> tag
      vulnerability.vul_analysis = this.wrapTextInStrongTag(vulnerability.vul_analysis, [
        'Explanation:',
        'Explanation of vulnerability:',
        'Explanation of vulnerability:',
        'Correction:',
        'Correction example:',
        'Here is the corrected code snippet:',
        'Here is an example of the corrected code:',
        'Vulnerability to fix:',
      ])
      vulnerability.vul_solution = this.wrapTextInStrongTag(vulnerability.vul_solution, [
        'Explanation:',
        'Here is the corrected code:',
        "Here's an example of the corrected code:",
        'Resources and guidance:',
        'Corrected code:',
        'Additional resources and guidance:',
        'Explanation and solution:',
        "Here's the corrected code snippet:",
        'Resources and Guidance:',
        'Correction Example:',
        "Here's an example of how you can fix the vulnerability:",
        "Here's an example of how to fix the vulnerability:",
        'Resources:',
        'Correction:',
        'Correction example:',
        'Here is the corrected code snippet:',
        'Here is an example of the corrected code:',
        'Vulnerability to fix:',
      ])

      vulnerability.vul_analysis = this.processTextContent(vulnerability.vul_analysis)
      vulnerability.vul_solution = this.processTextContent(vulnerability.vul_solution)

      vulnerability.vul_solution = this.cleanJsonData(vulnerability.vul_solution)
      vulnerability.vul_analysis = this.cleanJsonData(vulnerability.vul_analysis)

      tmp.push(vulnerability)
    })

    sortedVulnerabilities.push({
      vulnerabilities: tmp,
      isExpanded: false,
    })

    this.sortedVulnerabilities = sortedVulnerabilities
  }

  processWarnings() {
    let sortedWarnings: VulnerabilityCategory[] = []
    let tmp: Vulnerability[] = []
    this.warnings.forEach((vulnerability, index) => {
      vulnerability.id = `${index + 1}`
      // vulnerability.vul_solution = this.cleanJsonData(vulnerability.vul_solution)

      // vulnerability.position = this.highlightCodeBlocks(vulnerability.position)
      vulnerability.vul_analysis = this.highlightCodeBlocks(vulnerability.vul_analysis)
      vulnerability.vul_solution = this.highlightCodeBlocks(vulnerability.vul_solution)
      vulnerability.vul_solution = this.highlightCodeBlocksV2(vulnerability.vul_solution)

      // Wrap specific text with <strong> tag
      vulnerability.vul_analysis = this.wrapTextInStrongTag(vulnerability.vul_analysis, [
        'Explanation:',
        'Correction:',
        'Here is the corrected code snippet:',
        'Here is an example of the corrected code:',
        'Vulnerability to fix:',
      ])
      vulnerability.vul_solution = this.wrapTextInStrongTag(vulnerability.vul_solution, [
        'Explanation:',
        'Here is the corrected code:',
        "Here's an example of the corrected code:",
        'Resources and guidance:',
        'Corrected code:',
        'Additional resources and guidance:',
        'Explanation and solution:',
        "Here's the corrected code snippet:",
        'Resources and Guidance:',
        'Correction Example:',
        "Here's an example of how you can fix the vulnerability:",
        "Here's an example of how to fix the vulnerability:",
        'Resources:',
        'Explanation and Fix:',
        'Guidance:',
        'Correction:',
        'Here is the corrected code snippet:',
        'Here is an example of the corrected code:',
        'Vulnerability to fix:',
        'Recommendation:'
      ])

      vulnerability.vul_analysis = this.processTextContent(vulnerability.vul_analysis)
      vulnerability.vul_solution = this.processTextContent(vulnerability.vul_solution)

      vulnerability.vul_solution = this.cleanJsonData(vulnerability.vul_solution)
      vulnerability.vul_analysis = this.cleanJsonData(vulnerability.vul_analysis)

      tmp.push(vulnerability)
    })

    sortedWarnings.push({
      vulnerabilities: tmp,
      isExpanded: false,
    })

    this.sortedWarnings = sortedWarnings
  }

  processVulnerabilitiesPattern() {
    let sortedVulnerabilitiesPattern: VulnerabilityPatternCategory[] = []
    let tmp: VulnerabilityPattern[] = []
    this.vulnerabilitiesPatterns.forEach((vulnerability, index) => {
      vulnerability.id = `${index + 1}`

      vulnerability.vul_analysis = this.highlightCodeBlocks(vulnerability.vul_analysis)
      vulnerability.vul_solution = this.highlightCodeBlocks(vulnerability.vul_solution)
      vulnerability.vul_solution = this.highlightCodeBlocksV2(vulnerability.vul_solution)

      // Wrap specific text with <strong> tag
      vulnerability.vul_analysis = this.wrapTextInStrongTag(vulnerability.vul_analysis, [
        'Explanation:',
        'Correction:',
        'Here is the corrected code snippet:',
        'Here is an example of the corrected code:',
        'Vulnerability to fix:',
      ])
      vulnerability.vul_solution = this.wrapTextInStrongTag(vulnerability.vul_solution, [
        'Explanation:',
        'Here is the corrected code:',
        "Here's an example of the corrected code:",
        'Resources and guidance:',
        'Corrected code:',
        'Additional resources and guidance:',
        'Explanation and solution:',
        "Here's the corrected code snippet:",
        'Resources and Guidance:',
        'Correction Example:',
        "Here's an example of how you can fix the vulnerability:",
        "Here's an example of how to fix the vulnerability:",
        'Resources:',
        'Guidance:',
        'Correction:',
        'Here is the corrected code snippet:',
        'Here is an example of the corrected code:',
        'Vulnerability to fix:',
      ])

      vulnerability.vul_analysis = this.processTextContent(vulnerability.vul_analysis)
      vulnerability.vul_solution = this.processTextContent(vulnerability.vul_solution)

      vulnerability.vul_solution = this.cleanJsonData(vulnerability.vul_solution)
      vulnerability.vul_analysis = this.cleanJsonData(vulnerability.vul_analysis)

      // vulnerability.position = this.renderPositionList(vulnerability.position)
      vulnerability.position2 = JSON.parse(vulnerability.position)

      tmp.push(vulnerability)
    })

    sortedVulnerabilitiesPattern.push({
      vulnerabilities: tmp,
      isExpanded: false,
    })

    this.sortedVulnerabilitiesPattern = sortedVulnerabilitiesPattern
  }

  wrapTextInStrongTag(text: string, strongKeywords: string[]): string {
    strongKeywords.forEach((keyword) => {
      const regex = new RegExp(keyword, 'g')
      text = text.replace(regex, `<strong>${keyword}</strong>`)
    })
    return text
  }

  processTextContent(text: string): string {
    let processedText = this.highlightInlineCode(text)

    processedText = this.highlightCodeBlocks(processedText)

    // Making links clickable
    processedText = processedText.replace(
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim,
      '<a href="$1" target="_blank">$1</a>',
    )

    // Handling line breaks (emojis and new lines)
    processedText = processedText.replace(/\n/g, '<br/>').replace(/([\u{1F600}-\u{1F64F}])/gu, '<br/>$1')

    return processedText
  }

  highlightCodeBlocks(html: string): string {
    const codeBlockRegex = /```solidity\n([\s\S]*?)```/g
    return html.replace(codeBlockRegex, (match, code) => {
      const highlightedCode = Prism.highlight(this.escapeHtml(code), Prism.languages['solidity'], 'solidity')
      return `<pre><code class="language-solidity">${highlightedCode}</code></pre>`
    })
  }

  highlightCodeBlocksV2(html: string): string {
    const codeBlockRegex = /```\n([\s\S]*?)```/g
    return html.replace(codeBlockRegex, (match, code) => {
      const highlightedCode = Prism.highlight(this.escapeHtml(code), Prism.languages['solidity'], 'solidity')
      return `<pre><code class="language-solidity">${highlightedCode}</code></pre>`
    })
  }

  highlightCodeBlocksV3(html: string): string {
    const codeBlockRegex = /\['([\s\S]*?)'\]/g;
    return html.replace(codeBlockRegex, (match, code) => {
      const highlightedCode = Prism.highlight(this.escapeHtml(code), Prism.languages['solidity'], 'solidity');
      return `<pre><code class="language-solidity">${highlightedCode}</code></pre>`;
    });
  }

  getLastSegment(position: string): string {
    if (position) {
      const segments = position.split('/')
      return segments[segments.length - 1]
    }
    return ''
  }

  highlightInlineCode(html: string): string {
    // Regular expression to match text within single backticks
    const inlineCodeRegex = /`([^`]+)`/g

    return html.replace(inlineCodeRegex, (match, code) => {
      return `<code class="inline-code">${this.escapeHtml(code)}</code>`
    })
  }

  renderPositionList(positionsString: string): string {
    const positions = JSON.parse(positionsString) as string[]

    let positionContainer = '<div class="position-container">' // Start with a container div

    // Add a heading for clarity
    positionContainer += '<p>Positions:</p>'

    // Add a div to contain the list of positions
    positionContainer += `<div class="position-list">`

    // Iterate through each position and add it to the container
    positions.forEach((position) => {
      // Format the position as a div element
      positionContainer += `<div class="position-item">${position}</div>`
    })

    // Close the position list and container div
    positionContainer += '</div></div>'

    return positionContainer
  }

  escapeHtml(html: string): string {
    const text = document.createTextNode(html)
    const p = document.createElement('p')
    p.appendChild(text)
    return p.innerHTML
  }
}
