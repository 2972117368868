import { Component, Input } from '@angular/core';
import { GithubService } from 'src/app/github.service';

@Component({
    selector: 'app-github-login-button',
    templateUrl: './github-login-button.component.html',
    styleUrls: ['./github-login-button.component.css'],
    standalone: false
})
export class GithubLoginButtonComponent {
  @Input() isAuthenticated: boolean = false;
  @Input() isMobile: boolean = false;

  constructor(private githubService: GithubService) { }

  login() {
    this.githubService.login();
  }

}
