<div class="dialog-container">

    <h1 mat-dialog-title>Subscription {{data.currentStatus === 'Individual' ? 'Upgrade' : 'Downgrade'}} </h1>
    <mat-dialog-content>
        <p>Your current subscription plan is <strong>{{ data.currentStatus }}</strong>.</p>
        <p>Before proceeding, please confirm your intention to change your subscription plan to <strong>{{ data.newStatus }}</strong>. Please note that this action will result in the cancellation of your current plan, but any remaining credits will be preserved.</p>
    </mat-dialog-content>
    <div class="dialog-buttons">
        <button class="confirm" (click)="onConfirm()">Confirm</button>
        <button class="cancel" (click)="onCancel()">Cancel</button>
    </div>
</div>