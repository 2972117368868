<div class="menu-container">
  <div class="logo">
    <img src="assets/logosetinstone.webp" alt="Logo">
  </div>
  <nav class="menu-items" >
    <a routerLink="/" class="menu-item">
      ✨ Secure Your SmartContract
    </a>
    <a *ngIf="isLoggedIn && !isMobile && menuTabSwitch == 'prices'" (click)="seeContent()" class="menu-item">Dashboard</a>
    <a *ngIf="!isLoggedIn && menuTabSwitch == 'signUp' && !isMobile" (click)="signIn()" class="menu-item">SignIn</a>
    <a *ngIf="!isLoggedIn && menuTabSwitch != 'signUp' && !isMobile" (click)="signUp()" class="menu-item">SignUp</a>
    <a *ngIf="isLoggedIn && !isMobile" (click)="signOut()" class="menu-item">Sign Out</a>

    <a *ngIf="!isMobile && isLoggedIn && menuTabSwitch != 'prices' || !isLoggedIn" (click)="seePrices()" class="menu-item">{{ isLoggedIn ? 'Subscription' : 'Prices' }}</a>
  </nav>
  
</div>


<div class="container" *ngIf="!isMobile; else mobileMessage">  
  <app-dashboard-login *ngIf="!isLoggedIn && menuTabSwitch == 'login'"></app-dashboard-login>
  <app-dashboard-signup *ngIf="!isLoggedIn && menuTabSwitch == 'signUp'" (setFirstLogin)="setFirstLogin()"></app-dashboard-signup>
  <app-dashboard-content *ngIf="isLoggedIn && !firstLogin && menuTabSwitch != 'prices' || menuTabSwitch == 'content'" ></app-dashboard-content>

  <app-dashboard-prices *ngIf="menuTabSwitch == 'prices'" (signUp)="signUp()" [isLoggedIn]="isLoggedIn" [firstLogin]="firstLogin"></app-dashboard-prices>
</div>

<ng-template #mobileMessage>
  <p class="mobile-message">Sorry, this feature is only available on the desktop version.</p>
  <a routerLink="/" class="back-button">Back To Homepage</a>
</ng-template>