import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth.service';
import { ServerService } from 'src/app/server.service';

interface AuditReport {
  resultCreationTime: string;
  resultExpirationTime: string;
  github: string;
  reportURL: string;
  status: string;
  grade: number;
}

@Component({
  selector: 'app-dashboard-content',
  templateUrl: './dashboard-content.component.html',
  styleUrls: ['./dashboard-content.component.css']
})
export class DashboardContentComponent implements OnInit {
  auditReports: AuditReport[] = [];
  currentPage: number = 1;
  totalPages: number = 1;
  itemsPerPage: number = 50;
  loading: boolean = false;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private serverService: ServerService,
    private snackBar: MatSnackBar
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadAuditReports();
  }

  async loadAuditReports(): Promise<void> {
    this.loading = true;
    try {
      const response = await this.serverService.getAuditReportHistory(this.currentPage, this.itemsPerPage);
      this.auditReports = response.data[0].github === 'N/A' ? [] : response.data;
      this.totalPages = response.totalPages;
      this.currentPage = response.currentPage;
    } catch (error) {
      // console.error('Error fetching audit reports:', error);
      this.snackBar.open('Failed to load audit reports', 'Close', { duration: 3000 });
    } finally {
      this.loading = false;
    }
  }

  getRepoName(url: string): string {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

  isGithubRepo(url: string): boolean {
    return url.includes('github.com');
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.loadAuditReports();
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.loadAuditReports();
    }
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.loadAuditReports();
    }
  }
}