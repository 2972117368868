import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { AuthService } from 'src/app/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ServerService {
  
  
  /* eslint-disable @typescript-eslint/no-explicit-any */
  public logInGithub = new EventEmitter<any>();
  public logoutGithub = new EventEmitter<any>();
  public updateRepoData = new EventEmitter<any>();
  public updateIsSelectedFile = new EventEmitter<any>();

  // Service properties
  public requestUrl = environment.requestUrl;
  public dashboardUrl = environment.dashboardUrl;
  public headers: any;
  public isMobile: boolean = false;
  public isAuth: boolean = false;
  public repoData: any;
  public isRepoDataSet = false;
  public isSelectedFile = true;

  constructor(private http: HttpClient, private deviceService: DeviceDetectorService, private router: Router, private authService: AuthService) {
    this.isMobile = this.deviceService.isMobile();
    this.initializeEventListeners();
  }

  /**
   * Initialize event listeners for login, logout, and data updates.
   */
  private initializeEventListeners(): void {
    this.logInGithub.subscribe(() => {
      this.isAuth = true;
      window.location.href = environment.homeUrl;
    });

    this.logoutGithub.subscribe(() => {
      this.isAuth = false;
      window.location.href = environment.homeUrl;
    });

    this.updateRepoData.subscribe(() => {
      this.isRepoDataSet = true;
    });
  }

  /**
   * Generic method to make HTTP requests with authentication.
   * @param method - HTTP method (GET, POST, etc.)
   * @param url - Endpoint URL
   * @param type - Response type
   * @param data - Request payload
   * @returns Promise with the response
   */
  private async request(method: string, url: string, type: any, data?: any): Promise<any> {
    const timestamp = Math.floor(Date.now() / 1000);
    const sharedSecret = environment.secret;
    data = { ...data, timestamp };

    const signature = CryptoJS.HmacSHA256(JSON.stringify(data), sharedSecret).toString(CryptoJS.enc.Hex);
    
    const headers = new HttpHeaders({
      'x-access-token': environment.originCode,
      'x-signature': signature,
      'x-timestamp': timestamp.toString()
    });

    return new Promise((resolve, reject) => {
      this.http.request(method, url, {
        body: data,
        responseType: type,
        observe: 'response',
        headers: headers
      }).subscribe({
        next: (response) => resolve(response.body),
        error: (error) => {
          if (error.status === 404) {
            // Redirect to the 404 page
            console.log("error: "+error.toString());
            this.router.navigate(['/404']);
          }
          reject(error);
        }
      });
    });
  }

  
private async requestWithDashboardJWT(method: string, url: string, type: any, data?: any) {
  const timestamp = Math.floor(Date.now() / 1000); // Unix timestamp in seconds
  data !== null ? data.timestamp = timestamp : data = {timestamp};
  const sharedSecret = environment.secret;
  const jwt = this.authService.getToken();
  if (jwt === null || !jwt){
    console.log("no JWT");
    this.authService.logout();
    return;
  }


  // Sign the request with the shared secret
  const signature = CryptoJS.HmacSHA256(JSON.stringify(data), sharedSecret).toString(CryptoJS.enc.Hex);
      
  // Set signature in headers
  const tmpheaders = new HttpHeaders({
    'x-access-token':  environment.originCode, 
    'x-signature': signature,
    'x-timestamp': timestamp,
    'authorization': jwt,
    'x-dashboard-origin': environment.homeUrl
  })

  const result = this.http.request(method, url, {
    body: data,
    responseType: type,
    observe: 'response', // Changed from 'body' to 'response' to access status code
    headers: tmpheaders,
  });

  return new Promise((resolve, reject) => {
    result.subscribe({
      next: (response) => {
        resolve(response.body); // Resolve with the body of the response
      },
      error: (error) => {
        if (error.status === 404) {
          // Redirect to the 404 page
          this.router.navigate(['/404']);
        }
        if (error.status === 401) {
          // Redirect to the dashboard login
          this.authService.logout();
          this.router.navigate(['/dashboard']);
        }
        reject(error);
      },
    });
  }
  )
}


  /**
   * Special method for file upload requests.
   * @param method - HTTP method (typically POST for file uploads)
   * @param url - Endpoint URL
   * @param type - Response type
   * @param data - FormData containing the file
   * @returns Promise with the response
   */
  private async requestUpload(method: string, url: string, type: any, data: any): Promise<any> {
    const timestamp = Math.floor(Date.now() / 1000);
    const sharedSecret = environment.secret;

    const signature = CryptoJS.HmacSHA256(JSON.stringify(timestamp), sharedSecret).toString(CryptoJS.enc.Hex);
    
    const headers = new HttpHeaders({
      'x-access-token': environment.originCode,
      'x-signature': signature,
      'x-timestamp': timestamp.toString()
    });

    try {
      return await this.http.post<any>(url, data, {
        responseType: type,
        headers: headers
      }).toPromise();
    } catch (error: any) {
      if (error.status === 404) {
        this.router.navigate(['/404']);
      }
      throw error;
    }
  }

  /**
   * Send a token to the user's email.
   * @param obj - Object containing email details
   * @returns Promise with the response
   */
  SendTokenMail(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/sendTokenMail/`, 'json', obj);
  }

  /**
   * Send a report token to the user's email.
   * @param obj - Object containing email details
   * @returns Promise with the response
   */
  SendReportTokenMail(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/sendReportTokenMail/`, 'json', obj);
  }

  /**
   * Send a dashboard token to the user's email.
   * @param obj - Object containing email details
   * @returns Promise with the response
   */
  SendDashboardTokenMail(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/sendDashboardTokenMail/`, 'json', obj);
  }

  /**
   * Verify a token.
   * @param obj - Object containing the token
   * @returns Promise with the verification result
   */
  VerifyToken(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/verifyToken/`, 'json', obj);
  }

  /**
   * Verify a report token.
   * @param obj - Object containing the report token
   * @returns Promise with the verification result
   */
  VerifyReportToken(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/verifyReportToken/`, 'json', obj);
  }

  /**
   * Verify a dashboard token.
   * @param obj - Object containing the dashboard token
   * @returns Promise with the verification result
   */
  VerifyDashboardToken(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/verifyDashboardToken/`, 'json', obj);
  }

  /**
   * Get the size of a repository.
   * @param obj - Object containing repository details
   * @returns Promise with the repository size
   */
  getRepoSize(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/getRepoSize/`, 'json', obj);
  }

  /**
   * Upload a file.
   * @param formData - FormData containing the file to upload
   * @returns Promise with the upload result
   */
  async uploadFile(formData: FormData): Promise<any> {
    return this.requestUpload('POST', `${this.requestUrl}/upload`, 'json', formData);
  }

  /**
   * Handle GitHub callback.
   * @param obj - Object containing callback data
   * @returns Promise with the callback result
   */
  getCallback(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/callback/`, 'json', obj);
  }

  /**
   * Delete a token.
   * @param obj - Object containing the token to delete
   * @returns Promise with the deletion result
   */
  deleteToken(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/deleteToken/`, 'json', obj);
  }

  /**
   * Get reCAPTCHA score.
   * @param obj - Object containing reCAPTCHA data
   * @returns Promise with the reCAPTCHA score
   */
  getScore(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/getScore/`, 'json', obj);
  }

  /**
   * Handle hash for retrieving results.
   * @param obj - Object containing the hash
   * @returns Promise with the results
   */
  handleHash(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/results/`, 'json', obj);
  }

  /**
   * Handle hash for retrieving report.
   * @param obj - Object containing the hash
   * @returns Promise with the report
   */
  handleHashReport(obj: any): Promise<any> {
    return this.request('POST', `${this.requestUrl}/report/`, 'json', obj);
  }

  /**
   * Get display data for results.
   * @param obj - Object containing data request details
   * @returns Promise with the display data
   */
  async getDisplayData(obj: any): Promise<any> {
    try {
      return await this.request('POST', `${this.requestUrl}/resultData/`, 'json', obj);
    } catch (error) {
      console.error('Failed to fetch display data:', error);
      return 'Could not fetch the data from the API';
    }
  }


  // ----------------------------------------------------------------  DASHBOARD ---------------------------------------------------------------- //

   /**
   * Calling the HTTP request module to register a new user
   * @param user - The user object containing sign-up details
   * @returns 
   */
   async registerUser(user: { email: string; password: string }): Promise<any> {
    return await this.request('POST', `${this.dashboardUrl}/register`, 'json', user)
      .then((nextValue) => {
        return nextValue; // Return the successful result
      })
      .catch((errorValue) => {
        throw errorValue;
      });
  }

  /**
   * Calling the HTTP request module to register a new user
   * @param user - The user object containing sign-up details
   * @returns 
   */
  async loginUser(user: { email: string; password: string }): Promise<any> {
    return this.request('POST', `${this.dashboardUrl}/login`, 'json', user)
      .then((nextValue) => {
        return nextValue; // Return the successful result
      })
      .catch((errorValue) => {
        throw errorValue;
      });
  }

    /**
   * Calling the HTTP request module to request a password reset
   * @param email - The email associeted with account
   * @returns 
   */
  async requestPasswordReset(email: string): Promise<any> {
    const data = { email: email };
    return this.request('POST', `${this.dashboardUrl}/sendMailResetPassword`, 'json', data)
      .then((nextValue) => {
        return nextValue; // Return the successful result
      })
  }

  /**
   * Calling the HTTP request module to confirm the email on signup
   * @param email - The email associeted with account
   * @returns 
   */
  async requestSignupCode(email: string): Promise<any> {
    const data = { email: email };
    return this.request('POST', `${this.dashboardUrl}/sendMailSignupCode`, 'json', data)
      .then((nextValue) => {
        return nextValue; // Return the successful result
      })
  }

  /**
    * [ For Dashboard ] Calling the HTTP request module to call verify the token in api index
    * @param obj
    * @returns 
    * 
    */
  async VerifyTokenDashboard(obj: any): Promise<any> {
    const data = { data : obj}
    const res = this.request('POST', `${this.dashboardUrl}/verifyToken/`, 'json', data);
    return res;
  }

  /**
    * [ For Dashboard ] Calling the HTTP request module to delete the JWT token
    * @returns 
    * 
    */
  async logoutDashboard(): Promise<any> {
    const res = this.requestWithDashboardJWT('POST', `${this.dashboardUrl}/logoutDashboard`, 'json', null);
    return res;
  }

  /**
   * Calling the HTTP request module to change user password
   * @param user - The user object containing sign-up details
   * @returns 
   */
  async updatePassword(user: { email: string; password: string }): Promise<any> {
    return this.request('PUT', `${this.dashboardUrl}/updatePassword`, 'json', user)
      .then((nextValue) => {
        return nextValue; // Return the successful result
      })
      .catch((errorValue) => {
        throw errorValue;
      });
  }

  /**
 * Calling the HTTP request module to fetch audit report history with pagination
 * @param page - The page number to fetch (default: 1)
 * @param itemsPerPage - The number of items per page (default: 10)
 * @returns Promise with audit report data and pagination info
 */
async getAuditReportHistory(page: number = 1, itemsPerPage: number = 10): Promise<{
  data: any[];
  totalPages: number;
  currentPage: number;
}> {
  // Construct the API URL with pagination parameters
  const url = `${this.dashboardUrl}/auditReportHistory?page=${page}&itemsPerPage=${itemsPerPage}`;
  
  try {
    const response:any = await this.requestWithDashboardJWT('GET', url, 'json', null);
    
    // Assuming the API returns the data in the expected format
    // If not, you may need to transform the response here
    return {
      data: response.data || [],
      totalPages: response.totalPages || 1,
      currentPage: response.currentPage || page
    };
  } catch (error) {
    console.error('Error fetching audit report history:', error);
    throw error; // Re-throw the error for the component to handle
  }
}

/**
 * Get a Stripe payment link using the user's email.
 * @param email - The email of the user
 * @param subLvl - The subscription level of the user
 * @returns Promise with the payment link
 */
async getStripePaymentLink(subLvl: string): Promise<any> {

  return await this.requestWithDashboardJWT('GET', `${this.dashboardUrl}/getSubscriptionPaymentLink?subLvl=${subLvl}`, 'json', null)
    .then((response) => {
      return response; // Return the successful result
    })
    .catch((error) => {
      console.error('Error getting stripe subscription payment link:', error);
      throw error; // Handle error appropriately
    });
}

/**
 * Retrieve the current subscription information for a user.
 * @returns Promise with the user's subscription data
 */
async getSubscriptionUserData(): Promise<any> {
  const url = `${this.dashboardUrl}/sub/client`;
  
  try {
    const response = await this.requestWithDashboardJWT('GET', url, 'json', null);
    
    // Assuming the API returns the data in the expected format
    // If not, you may need to transform the response here
    return response; // Return the successful result
  } catch (error) {
    console.error('Error fetching subscription user data:', error);
    throw error; // Re-throw the error for the component to handle
  }
}

/**
 * Cancel the current subscription.
 * @returns Promise with the result of the cancellation
 */
async cancelSubscription(): Promise<any> {
  const url = `${this.dashboardUrl}/sub/cancel`;
  
  try {
    const response = await this.requestWithDashboardJWT('POST', url, 'json', null);
    
    return response; // Return the successful result
  } catch (error) {
    console.error('Error canceling subscription:', error);
    throw error; // Re-throw the error for the component to handle
  }
}

/**
 * Change the current subscription to a new plan.
 * @param subscriptionType - The new subscription type
 * @returns Promise with the result of the change
 */
async changeSubscription(subscriptionType: string): Promise<any> {
  const url = `${this.dashboardUrl}/sub/update`;
  
  try {
    const response = await this.requestWithDashboardJWT('POST', url, 'json', { subscriptionType });
    
    return response; // Return the successful result
  } catch (error) {
    console.error('Error updating subscription:', error);
    throw error; // Re-throw the error for the component to handle
  }
}



  
}