import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ServerService } from 'src/app/server.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UnsubscribeDialogComponent } from '../unsubscribe-dialog/unsubscribe-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dashboard-prices',
  templateUrl: './dashboard-prices.component.html',
  styleUrls: ['./dashboard-prices.component.css'],
  animations: [
    trigger('toggleAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in')
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class DashboardPricesComponent implements OnInit {
  billingCycle: 'monthly' | 'annually' = 'annually';
  creationDate: string='';
  nextUpdate: string='';
  remainingUses: number=0;
  status:  'Individual' | 'Professional' | 'Custom' | 'Canceled' = 'Individual';

  @Output() signUp = new EventEmitter<void>();
  @Input() isLoggedIn: boolean = false;
  @Input() firstLogin: boolean = false;

  constructor(
    private serverService: ServerService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar  
  ) {}
  
  ngOnInit() {
    if (this.isLoggedIn && !this.firstLogin) {
      this.getData();
    } 
  }

  setBillingCycle(cycle: 'monthly' | 'annually') {
    this.billingCycle = cycle;
  }

  subscribe() {
    this.signUp.emit(); // Emit the signUp event
  }

  async chooseSubscriptionPlan(subscriptionType: string) {
    let subLvl='';
    if (subscriptionType === 'pro') subLvl = this.billingCycle === 'annually' ? 'proYear' : 'proMonth';
    if (subscriptionType === 'ind') subLvl = this.billingCycle === 'annually' ? 'indYear' : 'indMonth';
    
    const data = await this.serverService.getStripePaymentLink(subLvl);
    window.location.href = data.paymentLink;
  }

  async getData() {
    const data = await this.serverService.getSubscriptionUserData();
    this.creationDate = new Date(data.creationDate).toLocaleDateString();
    this.nextUpdate = new Date(data.nextUpdate).toLocaleDateString();
    this.remainingUses = data.remainingUses;
    switch (data.status) {
      case 1:
        this.status = 'Individual';
        break;
      case 2:
        this.status = 'Professional';
        break;
      case 0:
        this.status = 'Canceled';
        break;
      case 3:
        this.status = 'Custom';
        break;
    }
    console.log({ creationDate: this.creationDate, nextUpdate: this.nextUpdate, remainingUses: this.remainingUses, status: this.status });
    
  }

  async cancelOrUpgrade(subscriptionType: string) {
    if (subscriptionType === this.status) {
      const dialogRef = this.dialog.open(UnsubscribeDialogComponent) as MatDialogRef<UnsubscribeDialogComponent>;

      dialogRef.componentInstance.confirmed.subscribe(async (confirmed: boolean) => {
        if (confirmed) {
          await this.serverService.cancelSubscription();
          this.status = 'Canceled';
          this.getData(); // Update the component with the new data
          dialogRef.close();
          this.snackBar.open('Subscription Successfully Canceled!', 'Close', {
            duration: 7000,
            panelClass: ["snackBarSuccess"]
          });
        } else {
          this.snackBar.open('There was an error while sending the verification code. Please try again.', 'Close', {
            duration: 7000,
            panelClass: ["snackBarError"]
          });
        }
      });

      dialogRef.componentInstance.canceled.subscribe(() => {
        dialogRef.close();
      });
    } else {
      // Upgrade/Downgrade
      await this.serverService.changeSubscription(subscriptionType);
    }
  }
}