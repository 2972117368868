import { NgModule } from '@angular/core';
import { provideRouter, RouterModule, Routes, withDebugTracing, withRouterConfig } from '@angular/router';
import { FormComponent } from './components/form/form.component';
import { GithubCallbackComponent } from './components/github-callback/github-callback.component';
import { ResultComponent } from './components/result/result.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ReportComponent } from './components/report/report.component';
import { DashboardMenuComponent } from './components/dashboard/dashboard-menu/dashboard-menu.component';
import { DashboardPaySuccessComponent } from './components/dashboard/dashboard-pay-success/dashboard-pay-success.component';
import { ScanReportComponent } from './components/scan-report/scan-report.component';

const routes: Routes = [
  { path: '', component: FormComponent },
  { path: 'github-callback', component: GithubCallbackComponent },
  { path: 'results/:param', component: ResultComponent },
  { path: 'report/:param', component: ReportComponent },
  { path: 'scan/:param', component: ScanReportComponent },
  { path: 'dashboard', component: DashboardMenuComponent },
  { path: 'dashboard/subscriptionPaymentSuccess', component: DashboardPaySuccessComponent },
  { path: '**', component: PageNotFoundComponent },
  { path: '404', component: PageNotFoundComponent },
];

@NgModule({

  providers: [
    provideRouter(routes,
      withDebugTracing(),
      withRouterConfig({ paramsInheritanceStrategy: 'always' })),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
