import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface UpgradeDialogData {
  currentStatus: string;
  newStatus: string;
}

@Component({
  selector: 'app-upgrade-dialog',
  templateUrl: './upgrade-dialog.component.html',
  styleUrls: ['./upgrade-dialog.component.css']
})
export class UpgradeDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<UpgradeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UpgradeDialogData
  ) {}

  onConfirm(): void {
    this.dialogRef.close(true); // Emit true to indicate confirmation
  }

  onCancel(): void {
    this.dialogRef.close(false); // Emit false to indicate cancellation
  }
}