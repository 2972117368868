import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DashboardForgotPasswordDialogComponent } from '../dashboard-forgot-password-dialog/dashboard-forgot-password-dialog.component';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { environment } from 'src/app/environments/environment';
import * as CryptoJS from 'crypto-js';
import { AuthService } from '../../../auth.service';
import { ServerService } from 'src/app/server.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-dashboard-login',
    templateUrl: './dashboard-login.component.html',
    styleUrls: ['./dashboard-login.component.css', '../../../app.component.css'],
    standalone: false
})
export class DashboardLoginComponent {
  email: string = '';
  password: string = '';
  signInForm: FormGroup;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private authService: AuthService,
    private serverService: ServerService,
    private snackBar: MatSnackBar
  ) {
    this.signInForm = this.fb.nonNullable.group({
      email: ['', [Validators.required, Validators.email, Validators.minLength(8), Validators.maxLength(50)]],
      password: ['', [Validators.required,Validators.minLength(8), Validators.maxLength(50)]],
    });
  }




  openForgotPasswordDialog(): void {
    const dialogRef = this.dialog.open(DashboardForgotPasswordDialogComponent, {
      width: '50%'
    });
  }

  async onSubmit(): Promise<void> {
   
    if (this.signInForm.valid) {
      const { email, password } = this.signInForm.value;
      const encryptedEmail = CryptoJS.AES.encrypt(email, environment.secretKey).toString();
      // Hash the password using SHA-256
      const hashedPassword = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);

      await this.serverService.loginUser({ email : encryptedEmail, password: hashedPassword })
        .then((response: any) => {
          if (response.message === 'Login successful') {
            this.authService.login(response.token);
            window.location.reload();
          } else {
           
            this.snackBar.open('An unexpected error occurred. Please try again.', 'Close', {
              duration: 7000,
              panelClass: ["snackBarError"]
            });
          }
        })
        .catch((error: any) => {
          // console.error('Login failed', error);
          this.snackBar.open('Wrong Credentials, please try again or Sign Up', 'Close', {
            duration: 7000,
            panelClass: ["snackBarError"]
          });
      });
    } 
  }
}