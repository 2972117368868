import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth.service';
import { ServerService } from 'src/app/server.service';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';

interface AuditReport {
  resultCreationTime: string;
  resultExpirationTime: string;
  content: string;
  hash: string;
  scanURL: string;
  status: string;
  grade: number;
  addressLink?: string;
  withReport: boolean;
}

@Component({
    selector: 'app-dashboard-content',
    templateUrl: './dashboard-content.component.html',
    styleUrls: ['./dashboard-content.component.css'],
    standalone: false
})
export class DashboardContentComponent implements OnInit {
  auditReports: AuditReport[] = [];
  currentPage: number = 1;
  totalPages: number = 1;
  itemsPerPage: number = 50;
  loading: boolean = false;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private serverService: ServerService,
    private snackBar: MatSnackBar,
    private http: HttpClient
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadAuditReports();
  }

  async loadAuditReports(): Promise<void> {
    this.loading = true;
    try {
      const response = await this.serverService.getAuditReportHistory(this.currentPage, this.itemsPerPage);
      this.auditReports = response.data[0].github === 'N/A' ? [] : response.data;
      this.totalPages = response.totalPages;
      this.currentPage = response.currentPage;
    } catch (error) {
      window.location.reload();
      
      this.snackBar.open('Failed to load audit reports', 'Close', { duration: 3000 });
    } finally {
      this.loading = false;
    }
  }

  getRepoName(url: string): string {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

  isGithubRepo(url: string): boolean {
    return url.includes('github.com');
  }

  isDeployedSC(url: string): boolean {
    return url.includes('chain');
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.loadAuditReports();
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.loadAuditReports();
    }
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.loadAuditReports();
    }
  }

  formatGrade(grade: number): string {
    return grade.toFixed(2);
  }

  async deleteReport(hash: string): Promise<void> {
    const result = await Swal.fire({
      title: "<span style='color: white;'>Confirm Deletion</span>",
      text: "Are you sure you want to delete this report?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: "<span style='color: white;'>Delete</span>",
      cancelButtonText: "<span style='color: white;'>Cancel</span>",
      background: '#1C1C1C',
      iconColor: '#FF0000',
    });

    if (result.isConfirmed) {
      try {
        await this.serverService.deleteReport(hash);
        this.snackBar.open('Report deleted successfully', 'Close', { duration: 3000 });
        // Reload the audit reports after deletion
        await this.loadAuditReports();
      } catch (error) {
        console.error('Error deleting report:', error);
        this.snackBar.open('Failed to delete report', 'Close', { duration: 3000 });
      }
    }
  }
}