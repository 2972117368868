import { Component } from '@angular/core';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.css'],
    standalone: false
})
export class PageNotFoundComponent {
  contactSupport(): void {
    const email = 'support.error@setinstone.io';
    const subject = 'Support Request';
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
    window.location.href = mailtoLink;
  }
}
