// github.component.ts
import { MatSelectChange } from '@angular/material/select'
import { Component, Input, OnInit } from '@angular/core'
import { GithubService } from '../../github.service'
import { ServerService } from 'src/app/server.service'
import { environment } from 'src/app/environments/environment'

@Component({
    selector: 'app-github',
    templateUrl: './github.component.html',
    styleUrls: ['./github.component.css'],
    standalone: false
})

/* eslint-disable @typescript-eslint/no-explicit-any */
export class GithubComponent implements OnInit {
  // Tracks user authentication state
  isAuthenticated: boolean = false;

  // Input properties for mobile detection and form data binding
  @Input() isMobile: boolean = false;
  @Input() formData: any;

  // Data for repositories, branches, and commits
  repos: any[] = [];
  branches: any[] = [];
  commits: any[] = [];

  // Flags for loading state of repos, branches, and commits
  reposLoaded: boolean = false;
  branchesLoaded: boolean = false;
  commitsLoaded: boolean = false;

  // Flag to check if a file is selected
  isSelectedFile: boolean = false;

  // Selected repository, branch, and commit
  selectedRepo: string = '';
  selectedBranch: string = '';
  selectedCommit: string = '';

  // GitHub username and an object to store selected repo data
  username: string = '';
  obj: any = {};

  constructor(
    private githubService: GithubService, // Injects the GitHub service
    public server: ServerService // Injects the server service
  ) { }

  ngOnInit() {
    // Subscribes to the logout event from the server service
    this.server.logoutGithub.subscribe(() => {
      this.resetGitHubData();
    });

    // Subscribes to the file selection event
    this.server.updateIsSelectedFile.subscribe(() => {
      this.isSelectedFile = this.server.isSelectedFile;
    });

    // Subscribes to GitHub authentication state changes
    this.githubService.isAuthenticated$.subscribe((isAuth: boolean) => {
      if (isAuth) {
        this.isAuthenticated = true
        this.server.isAuth = true
      }
    })

    // If authenticated, fetch user repos
    if (this.isAuthenticated) {
      this.githubService.getRepos().subscribe((repos: any) => {
        this.repos = repos
        this.reposLoaded = true
      })

      //Retrieve user details if logged in
      this.githubService.getUserDetails().subscribe(
        (userDetails) => {
          this.username = userDetails.data.login
          //Retrieve app installed on user account
          this.githubService.getUserInstall().subscribe(async (res: any) => {
            if (res.status == 200) {
              //If no app installed on user account, redirect to our github app installation page
              if (res.data.installations.length == 0) {
                window.location.href = `https://github.com/apps/${environment.appName}/installations/new`
              } else {
                let installed = false;
                //If app already installed, check for our App with the appID.
                if (res.data.installations) {
                  for (let e of res.data.installations) {
                    if (e.app_id == environment.appId) {
                      installed = true;
                    }
                  }
                }
                //If user don't have our appID in his installations, redirect to github app install page
                if (!installed) {
                  window.location.href = `https://github.com/apps/${environment.appName}/installations/new`
                }
              }
            }
          })
        },
        (error) => {
          console.error('Error fetching user details:', error)
        },
      )
    }
  }

  // Resets GitHub-related data
  resetGitHubData() {
    this.isAuthenticated = false;
    this.repos = [];
    this.branches = [];
    this.commits = [];
    this.reposLoaded = false;
    this.branchesLoaded = false;
    this.commitsLoaded = false;
  }

  // Handles repository selection change
  onRepoSelectionChange(newValue: MatSelectChange) {
    if (newValue.value != undefined) {
      const selectedRepo = this.repos.find((repo) => repo.name === newValue.value);
      const sizeInMb = selectedRepo.size / 1024;
      if (selectedRepo) {
        const encryptedAccessToken = sessionStorage.getItem('token');
        if (encryptedAccessToken) {
          // Fetches branches for the selected repository
          this.githubService.getBranches(this.selectedRepo, this.username).subscribe((branches: any) => {
            this.branches = branches;
            this.branchesLoaded = true;
          });

          // Stores repository data
          this.obj = {
            repo: newValue.value,
            size: sizeInMb,
            username: selectedRepo.owner.login,
            key: encryptedAccessToken,
            url: selectedRepo.html_url,
          };

          // Updates server with repository data
          this.server.repoData = this.obj;
          this.server.updateRepoData.emit();

          // Clear previous selections
          this.clearBranchesAndCommits();  // Ensure this method resets branches and commits
        }
      }
    } else {
      this.clearBranchesAndCommits();
    }
  }

  // Clears branches and commits data
  clearBranchesAndCommits() {
    this.branches = [];
    this.commits = [];
    this.branchesLoaded = false;
    this.commitsLoaded = false;
    this.selectedBranch = '';  // Clear selected branch
    this.selectedCommit = '';   // Clear selected commit
    this.server.isRepoDataSet = false;
    this.server.repoData = '';
  }

  // Handles branch selection change
  onBranchSelectionChange(newValue: MatSelectChange): void {
    if (newValue.value != undefined) {
      this.selectedBranch = newValue.value;

      // Fetches commits for the selected branch
      this.githubService.getCommits(this.selectedRepo, this.selectedBranch, this.username).subscribe((commits: any) => {
        this.commits = commits;
        this.commitsLoaded = true;
      });

      // Updates formData and server with branch data
      this.formData.branch = this.selectedBranch;
      this.obj.branch = this.selectedBranch;
      this.server.repoData = this.obj
      this.server.updateRepoData.emit()
    } else {
      this.clearCommits();
    }
  }

  // Clears commits data
  clearCommits() {
    this.commits = [];
    this.commitsLoaded = false;
  }

  // Handles commit selection change
  onCommitSelectionChange(newValue: MatSelectChange): void {
    if (newValue.value !== undefined) {
      this.selectedCommit = newValue.value;

      // Updates formData and server with commit data
      this.formData.commit = this.selectedCommit;
      this.obj.commit = this.selectedCommit;
      this.server.repoData = this.obj;
      this.server.updateRepoData.emit();
    }
  }
}
