import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormComponent } from './components/form/form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ServerService } from './server.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GithubComponent } from './components/github/github.component';
import { GithubCallbackComponent } from './components/github-callback/github-callback.component';
import { GithubIconComponent } from './components/github-icon/github-icon.component';
import { GithubLoginButtonComponent } from './components/github-login-button/github-login-button.component';
import { GithubLogoutButtonComponent } from './components/github-logout-button/github-logout-button.component';
import { environment } from './environments/environment';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { ResultComponent } from './components/result/result.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ReportComponent } from './components/report/report.component';
import { ReportAuthComponent } from './components/report-auth/report-auth.component';
import { DatePipe } from '@angular/common';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatListModule } from '@angular/material/list';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { DashboardMenuComponent } from './components/dashboard/dashboard-menu/dashboard-menu.component';
import { DashboardLoginComponent } from './components/dashboard/dashboard-login/dashboard-login.component';
import { DashboardSignupComponent } from './components/dashboard/dashboard-signup/dashboard-signup.component';
import { DashboardForgotPasswordDialogComponent } from './components/dashboard/dashboard-forgot-password-dialog/dashboard-forgot-password-dialog.component';
import { DashboardChangePasswordComponent } from './components/dashboard/dashboard-change-password/dashboard-change-password.component';
import { DashboardContentComponent } from './components/dashboard/dashboard-content/dashboard-content.component';
import { MatSortModule } from '@angular/material/sort';
import { DashboardPricesComponent } from './components/dashboard/dashboard-prices/dashboard-prices.component';
import { DashboardPaySuccessComponent } from './components/dashboard/dashboard-pay-success/dashboard-pay-success.component';
import { UnsubscribeDialogComponent } from './components/dashboard/unsubscribe-dialog/unsubscribe-dialog.component';
import { UpgradeDialogComponent } from './components/dashboard/upgrade-dialog/upgrade-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    FormComponent,
    GithubComponent,
    GithubCallbackComponent,
    GithubIconComponent,
    GithubLoginButtonComponent,
    GithubLogoutButtonComponent,
    ResultComponent,
    PageNotFoundComponent,
    ReportComponent,
    ReportAuthComponent,
    ProgressBarComponent,
    DashboardMenuComponent,
    DashboardLoginComponent,
    DashboardSignupComponent,
    DashboardSignupComponent,
    DashboardForgotPasswordDialogComponent,
    DashboardChangePasswordComponent,
    DashboardContentComponent,
    CustomDatePipe,
    DashboardPricesComponent,
    DashboardPaySuccessComponent,
    UnsubscribeDialogComponent,
    UpgradeDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    HttpClientModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatSelectModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatIconModule,
    RecaptchaV3Module,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatListModule,
    MatCheckboxModule,
    MatRadioModule,
    DatePipe,
    MatPaginatorModule,
    MatSortModule
  ],
  providers: [ServerService, DeviceDetectorService, { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaKey }, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }