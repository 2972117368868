<div class="pricing-container">
    <h1 *ngIf="!isLoggedIn">Pricing Plans</h1>
    <h1 *ngIf="isLoggedIn && !firstLogin">Update Your Subscription Plan</h1>
    <h1 *ngIf="firstLogin">Your account is created, now please choose a subscription plan</h1>
    <div class="billing-toggle">
        <span class="toggle-option" [class.active]="billingCycle === 'annually'" (click)="setBillingCycle('annually')">Annually</span>
        <span class="toggle-option" [class.active]="billingCycle === 'monthly'" (click)="setBillingCycle('monthly')">Monthly</span>
    </div>

    <div class="pricing-cards">
        <div class="card">
            <h2>Individual</h2> <br> <br>
            <p [@toggleAnimation]="billingCycle === 'monthly' ? 'in' : 'out'"
            >{{ billingCycle === 'monthly' ? '19,99€ / Month' : '14,99€ / Month' }}</p>
            <p>1 Audit / Month</p>
            <p>Designed for solo developers or small projects</p>
            <small> Includes 1 security audit per month to identify vulnerabilities and strengthen smart contracts. Affordable for individuals.</small> <br>
            <button class="subscriptionBtn" (click)="subscribe()" *ngIf="!isLoggedIn && !firstLogin">Subscribe</button>
            <button class="subscriptionBtn" (click)="chooseSubscriptionPlan('ind')" *ngIf="isLoggedIn && firstLogin || status === 'Canceled'">Subscribe</button>
            <button class="subscriptionBtn {{ status === 'Individual' ? 'currentSub':''}}" (click)="cancelOrUpgrade('Individual')" *ngIf="isLoggedIn && !firstLogin && status !== 'Canceled'">{{ status === 'Individual' ? 'Cancel' : 'Downgrade' }}</button>
        </div>

        <div class="card popular">
            <h2>Professional  <br><small class="popular-label">⭐ Popular ⭐</small></h2>
            <p [@toggleAnimation]="billingCycle === 'monthly' ? 'in' : 'out'"
            >{{ billingCycle === 'monthly' ? '99,99€ / Month' : '79,99€ / Month' }}</p>
            <p>10 Audits / Month</p>
            <p>Ideal for teams or growing projects</p>
            <small>Offering 10 audits per month for enhanced security coverage. </small> <br>
            <button class="subscriptionBtn" (click)="subscribe()" *ngIf="!isLoggedIn && !firstLogin">Subscribe</button>
            <button class="subscriptionBtn" (click)="chooseSubscriptionPlan('pro')" *ngIf="isLoggedIn && firstLogin || status === 'Canceled'">Subscribe</button>
            <button class="subscriptionBtn {{ status === 'Professional' ? 'currentSub':''}}" (click)="cancelOrUpgrade('Professional')" *ngIf="isLoggedIn && !firstLogin && status !== 'Canceled'">{{ status === 'Professional' ? 'Cancel' : 'Upgrade' }}</button>
        </div>

        <div class="card custom">
            <br><br>
            <h2>Custom</h2>
            <br> <br>
            <p>Tailored solutions for larger projects or specific needs. Book a call for personalized plans.</p>
            <a href="https://calendly.com/set-in-stone-thomas-benoit/setinstone-demo?back=1&month=2024-10" target="_blank">Book A Call</a>
        </div>
    </div>
</div>

<div class="grid-container" *ngIf="isLoggedIn && !firstLogin">
    <div class="grid-item">Current Plan: {{ status }}</div>
    <div class="grid-item">Creation Date: {{ creationDate }}</div>
    <div class="grid-item">Remaining Uses: {{ remainingUses }}</div>
    <div class="grid-item">Next Update: {{ nextUpdate }}</div>  
</div>

