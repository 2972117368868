<div class="container">
  <h1 class="api-title">API Key Management</h1>
  
  <div class="api-creation">
    <form [formGroup]="apiForm" (ngSubmit)="onSubmit()">
      <h2>Create a New API Key</h2>
      <div class="form-group">
        <label for="label">API Key Label:</label>
        <input id="label" formControlName="label" required>
      </div>
      <button type="submit" class="get-report-button">Create API Key</button>
    </form>

    <div *ngIf="generatedKey" class="generated-key">
      <h3>Generated API Key:</h3>
      <p>{{ generatedKey }}</p>
      <button (click)="copyToClipboard()" class="copy-button">Copy Key</button>
      <p class="warning-message">{{ warningMessage }}</p>
    </div>
  </div>

  <div class="api-keys-list">
    <h2>Your API Keys</h2>
    <ul>
      <li *ngFor="let apiKey of apiKeys" class="api-key-item">
        <span>{{ apiKey.label }} - Created At: {{ apiKey.createdAt | date: 'MM/dd/yy - hh:mm a' }} - Remaining Uses : {{ apiKey.remainingUses }}</span>
        <button (click)="apiKey.showHistoric = !apiKey.showHistoric" class="dropdown-button">
          {{ apiKey.showHistoric ? 'Hide Historic' : 'Show Historic' }}
        </button>
        <div *ngIf="apiKey.showHistoric" class="historic-dropdown">
          <ul class="historic-list">
            <li *ngIf="apiKey.historic.length === 0" class="historic-item">
              Not used yet
            </li>
            <li *ngFor="let historic of apiKey.historic" class="historic-item">
    
                <span class="timestamp">{{ historic.timestamp | date: 'MM/dd/yy - hh:mm a' }}</span>
                <span class="historic-type">{{ historic.type }}</span>
           
            </li>
          </ul>
        </div>
        <button (click)="deleteApiKey(apiKey.key)" class="delete-button">Delete</button>
      </li>
    </ul>
  </div>
</div>