import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth.service';
import { ServerService } from 'src/app/server.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.css']
})



export class DashboardMenuComponent implements OnInit {
  isLoggedIn: boolean = false;
  menuTabSwitch: menutab = menutab.login;
  isMobile: boolean;
  firstLogin: boolean=false;

  creationDate: string='';
  nextUpdate: string='';
  remainingUses: number=0;
  status: number=0;

  constructor(
    private authService: AuthService,
    private serverService: ServerService,
    private deviceService: DeviceDetectorService
  ) {
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isLoggedIn();
    return
  }

  signIn(): void {
    this.menuTabSwitch = menutab.login;
  }

  signUp(): void {
    this.menuTabSwitch = menutab.signUp;
  }

  signOut(): void {
    // console.log(this.authService.getToken());
    this.serverService.logoutDashboard();
    this.authService.logout();
    this.isLoggedIn = false;
    this.menuTabSwitch = menutab.login;
  }

  seePrices(): void {
    this.menuTabSwitch = menutab.seePrices;
  }

  seeContent(): void {
    this.menuTabSwitch = menutab.content;
  }

  setFirstLogin(): void {
    this.isLoggedIn = this.authService.isLoggedIn();
    this.firstLogin = true;
    this.seePrices();
  }

}
export enum menutab {
  login = "login",
  signUp = "signUp",
  content = "content",
  seePrices = "prices"
};
