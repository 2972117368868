import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ServerService } from 'src/app/server.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UnsubscribeDialogComponent } from '../unsubscribe-dialog/unsubscribe-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/app/environments/environment';
import { UpgradeDialogComponent } from '../upgrade-dialog/upgrade-dialog.component';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-dashboard-prices',
    templateUrl: './dashboard-prices.component.html',
    styleUrls: ['./dashboard-prices.component.css'],
    animations: [
        trigger('toggleAnimation', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [
                style({ opacity: 0 }),
                animate('300ms ease-in')
            ]),
            transition(':leave', [
                animate('300ms ease-out', style({ opacity: 0 }))
            ])
        ])
    ],
    standalone: false
})
export class DashboardPricesComponent implements OnInit {
  billingCycle: 'monthly' | 'annually' = 'annually';
  
  individualPriceMonthly = environment.individualPriceMonthly;
  individualPriceAnnually = environment.individualPriceAnnually;
  professionalPriceMonthly = environment.professionalPriceMonthly;
  professionalPriceAnnually = environment.professionalPriceAnnually;
  individualAuditDescription = environment.individualAuditDescription;
  professionalAuditDescription = environment.professionalAuditDescription;

  @Output() signUp = new EventEmitter<void>();
  @Output() getData = new EventEmitter<void>();
  @Input() isLoggedIn: boolean = false;
  @Input() firstLogin: boolean = false;
  @Input() status: 'Individual' | 'Professional' | 'Custom' | 'Canceled' = 'Individual';
  showDialog: boolean = false;
  termsAccepted: boolean = false;
  functionToCall: string = '';
  functionToCallPlan: string = '';

  constructor(
    private serverService: ServerService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar  
  ) {}
  
  ngOnInit() {
    if (this.isLoggedIn && !this.firstLogin) {
      this.getDataToCheckFirstLogin();
    } 
  }

  setBillingCycle(cycle: 'monthly' | 'annually') {
    this.billingCycle = cycle;
  }

  subscribe() {
    this.signUp.emit(); // Emit the signUp event
  }

  async getDataToCheckFirstLogin() {
    const data = await this.serverService.getSubscriptionUserData();
    if (data.message){
      this.firstLogin = true;
    } 
  }

  async chooseSubscriptionPlan(subscriptionType: string) {
    let subLvl='';
    if (subscriptionType === 'pro') subLvl = this.billingCycle === 'annually' ? 'proYear' : 'proMonth';
    if (subscriptionType === 'ind') subLvl = this.billingCycle === 'annually' ? 'indYear' : 'indMonth';
    
    const data = await this.serverService.getStripePaymentLink(subLvl, this.billingCycle);
    window.location.href = data.paymentLink;
  }

  async cancelOrUpgrade(subscriptionType: string) {
    if (subscriptionType === this.status) {
      const dialogRef = this.dialog.open(UnsubscribeDialogComponent) as MatDialogRef<UnsubscribeDialogComponent>;

      dialogRef.componentInstance.confirmed.subscribe(async (confirmed: boolean) => {
        if (confirmed) {
          await this.serverService.cancelSubscription();
          this.status = 'Canceled';
          this.getData.emit(); // Update the component with the new data
          dialogRef.close();
          this.snackBar.open('Subscription Successfully Canceled!', 'Close', {
            duration: 7000,
            panelClass: ["snackBarSuccess"]
          });
        } else {
          this.snackBar.open('There was an error while sending the verification code. Please try again.', 'Close', {
            duration: 7000,
            panelClass: ["snackBarError"]
          });
        }
      });

      dialogRef.componentInstance.canceled.subscribe(() => {
        dialogRef.close();
      });
    } else {
      // Upgrade/Downgrade
      const upgradeDialogRef = this.dialog.open(UpgradeDialogComponent, {
        data: {
          currentStatus: this.status,
          newStatus: subscriptionType
        }
      }) as MatDialogRef<UpgradeDialogComponent>;

      upgradeDialogRef.afterClosed().subscribe(async (confirmed: boolean) => {
        if (confirmed) {
          let subLvl = '';
          if (subscriptionType === 'Professional') subLvl = this.billingCycle === 'annually' ? 'proYear' : 'proMonth';
          if (subscriptionType === 'Individual') subLvl = this.billingCycle === 'annually' ? 'indYear' : 'indMonth';
          const data = await this.serverService.changeSubscription(this.status, subLvl, this.billingCycle);
          window.location.href = data.paymentLink;
        } 
      });
    }
  }

  openConfirmationDialog(functionToCall: string, plan: string) {
    this.functionToCall = functionToCall;
    this.functionToCallPlan = plan;
    Swal.fire({
      title: "<span style='color: white;'>Confirm Subscription</span>",
      html: `
          <p style='color: white;'>By checking this box, I declare that I have read the 
            <a target="_blank" href="${environment.terms_n_conditions_url}" style="color:#327842">general conditions</a>
             of use of the website and accept them.
          </p>
          <br />
          <input type="checkbox" id="termsCheckbox" />
          <label for="termsCheckbox" style="color: white;">I accept the terms and conditions</label>
      `,
      background: '#1C1C1C',
      iconColor: '#FF0000',
      confirmButtonColor: '#00B050',
      confirmButtonText: "<span class='subscriptionBtn'>Confirm</span>",
      showCancelButton: true,
      cancelButtonText: "<span class='currentSub'>Cancel</span>",
      preConfirm: () => {
          const checkbox = document.querySelector("#termsCheckbox") as HTMLInputElement;
          // console.log(checkbox.checked)
          if (!checkbox.checked) {
            Swal.showValidationMessage('You must accept the terms and conditions');
          }
          return checkbox;
      }
    }).then((result) => {
      if (result.isConfirmed) {
          this.termsAccepted = true; // Set termsAccepted to true
          this.confirmAction(); // Call the confirmAction method to proceed
      } else {
          this.termsAccepted = false; // Reset if canceled
      }
    });
  }

  async confirmAction() {
    if (this.termsAccepted) {
      if (this.functionToCall == 'chooseSubscriptionPlan') {
        await this.chooseSubscriptionPlan(this.functionToCallPlan);
      } else if (this.functionToCall == 'cancelOrUpgrade') {
        await this.cancelOrUpgrade(this.functionToCallPlan);
      } 
    }
  }

}